import { useState, useEffect, useRef, forwardRef } from "react";
import PropTypes from "prop-types";
import { Switch, TextField, ListItemIcon, ListItemText, Autocomplete } from "@mui/material";
// cmps
import Svg from "./svg";
// services
import Gupport from "../services/gupport";
import Constants from "../services/constants";
// types
import type { ChangeEvent, SyntheticEvent } from "react";
import type { ReadonlyDeep, ValueOf } from "type-fest";
import type { MsgResponseSearch, SearchDataGateway, SearchDataUser } from "../types/message";
import type { Option } from "../types/misc";

type SearchData = {
	gateway: SearchDataGateway;
	user: SearchDataUser;
};

type Props = {
	placeholder: string;
	value: string;
	kind: ValueOf<typeof Constants.Kind>;
	onItemSelected: (option: Option) => void;
	onClear: () => void;
	onAdvanceSearchToggle: (isEnabled: boolean) => void;
};

const DEFAULT_OPTION = {
	id: "",
	label: "",
	iconPath: "",
	primary: "",
	secondary: ""
} as const satisfies Option;

const SearchAutocomplete = forwardRef<HTMLInputElement, Props>((props, ref) => {
	const timeoutIdRef = useRef<number | undefined>(undefined);

	const [advancedSearchEnabled, setAdvancedSearchEnabled] = useState(false);
	const [searchText, setSearchText] = useState(props.value);
	const [optionsLoading, setOptionsLoading] = useState(false);
	const [options, setOptions] = useState<Array<Option>>([]);
	const [selectedItem, setSelectedItem] = useState<Option>({ ...DEFAULT_OPTION, label: props.value });

	useEffect(() => (
		() => {
			window.clearTimeout(timeoutIdRef.current);
		}
	), []);

	useEffect(() => {
		if (props.value) {
			setSearchText(props.value);
			setSelectedItem({ ...DEFAULT_OPTION, label: props.value });
		}
	}, [props.value]);

	const handleToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setAdvancedSearchEnabled(event.target.checked);
		props.onAdvanceSearchToggle(event.target.checked);
	};

	const createOptions = (item: ReadonlyDeep<SearchData[typeof props.kind]>): Option => {
		if (item.kind === Constants.Kind.User) {
			const primary = (item.name.length > 0) ? item.name : item.username;
			return {
				id: item.id,
				label: `${primary} (${item.username})`,
				iconPath: "add/add-user.svg",
				primary: primary,
				secondary: item.username
			} as const satisfies Option;
		} else if (item.kind === Constants.Kind.Gateway) {
			const primary = (item.label.length > 0) ? item.label : item.id;
			return {
				id: item.id,
				label: `${primary} (${item.id}) [${item.code}]`,
				iconPath: "add/add-hub.svg",
				primary: primary,
				secondary: `${item.id} [${item.code}]`
			} as const satisfies Option;
		} else {
			return DEFAULT_OPTION;
		}
	};

	const handleSearchResult = (error: Error | null, msg: MsgResponseSearch) => {
		if (!error && msg.payload.status === "ok") {
			const options = (msg.payload.data as ReadonlyDeep<Array<SearchData[typeof props.kind]>>)
				.filter((item, index, array) => (array.findIndex((el) => (el.id === item.id)) === index)) // TODO: remove deduplication in future
				.map((item) => createOptions(item));

			setOptions(options);
		} else {
			setOptions([]);
		}
		setOptionsLoading(false);
	};

	const handleInputChange = (event: SyntheticEvent, value: string/*, reason: AutocompleteInputChangeReason*/) => {
		setSearchText(value);
		window.clearTimeout(timeoutIdRef.current);

		timeoutIdRef.current = window.setTimeout(() => {
			setOptionsLoading(true);

			if (advancedSearchEnabled) {
				Gupport.searchB2CUser({
					query: value
				}, handleSearchResult);
			} else {
				Gupport.search({
					kind: props.kind,
					query: value
				}, handleSearchResult);
			}
		}, 500);
	};

	const handleChange = (event: SyntheticEvent, value: Option | null/*, reason: AutocompleteChangeReason, details: AutocompleteChangeDetails*/) => {
		if (value === null) {
			setSearchText("");
			setOptions([]);
			setSelectedItem(DEFAULT_OPTION);
			props.onClear();
		} else {
			setSearchText(value.label);
			setSelectedItem(value);
			props.onItemSelected(value);
		}
	};

	return (
		<div style={{ display: "flex", position: "relative" }}>
			<Autocomplete
				id="autocomplete_search"
				className="child-input"
				fullWidth={true}
				loading={optionsLoading}
				options={options}
				filterOptions={(options/*, state*/) => (options)}
				isOptionEqualToValue={(option, value) => (option.id === value.id)}
				inputValue={searchText}
				onInputChange={handleInputChange}
				value={selectedItem}
				onChange={handleChange}
				renderInput={(params) => (
					<TextField inputRef={ref} autoFocus={true} {...params} placeholder={props.placeholder} />
				)}
				renderOption={(props, option/*, state*/) => (
					<li {...props}>
						<ListItemIcon><Svg src={option.iconPath} /></ListItemIcon>
						<ListItemText primary={option.primary} secondary={option.secondary} />
					</li>
				)}
				style={{ marginRight: "24px" }}
			/>
			{Gupport.hasLevel("user_b2c_read") && <Switch checked={advancedSearchEnabled} onChange={handleToggleChange} />}
		</div>
	);
});

if (process.env.NODE_ENV !== "production") {
	SearchAutocomplete.displayName = "SearchAutocomplete";
}

SearchAutocomplete.propTypes = {
	placeholder: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	kind: PropTypes.oneOf(Object.values(Constants.Kind)).isRequired,
	onItemSelected: PropTypes.func.isRequired,
	onClear: PropTypes.func.isRequired,
	onAdvanceSearchToggle: PropTypes.func.isRequired
};

export default SearchAutocomplete;
